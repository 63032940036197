import {FC} from "react";

export const FuturePlans: FC = () => {
    return (
        <>
            <h2>Планы по развитию проекта</h2>
            <ol>
                <li>Разработать мобильную версию сервиса</li>
                <li>Разработать и настроить роботов для добавления записей из различных источников - социальных сетей, форумов</li>
                <li>Разработать фильтрацию по населенным пунктам</li>
                <li>Сделать поиск и фильтрацию по записям</li>
                <li>Разработать дизайн сервиса</li>
            </ol>
        </>)
}