import {FC} from "react";
import {DonateBlock} from "../donate-block";
import {Link} from "react-router-dom";
import {FuturePlans} from "../future-plans";


export const AboutCommon = () => (
    <div>Проект существует и разрабатывается на некоммерческой основе в свободное время, поэтому <strong>нестабилен в
        выходные и вечернее время.</strong></div>
)

export const About: FC = () => {
    return (<div>
        <h2>О проекте</h2>
        <div><Link to={''}>fndrtrn.com</Link> - это некоммерческий проект, который задумывался, как единая точка поиска
            потерянных и
            найденных вещей. Здесь вы можете разместить объявление о находке или пропаже вещи, животного и т. д.
        </div>
        <AboutCommon/>
        <hr/>
        <div><strong>Если вы представляете службу находок или организацию,</strong> которая занимается розыском
            пропавших вещей, мы
            предлагаем вам бесплатно размещать информацию о вас, ваших находках и пропажах. Все размещенные объявления о
            находках и пропажах публикуются на сайте без передачи персональных данных.
        </div>

        <div><strong>Если вы не представляете службу находок или организацию,</strong> но желаете активно участвовать в
            жизни проекта - делиться новыми идеями, помогать в поиске потерянных вещей и животных, вступить в ряды
            сознательных граждан. Мы рады приветствовать вас в команде.
        </div>
        <hr/>
        <FuturePlans/>
        <hr/>
        <h2>Контакты для связи</h2>
        <ul>
            <li><a href={'https://t.me/fndrtrn'}>Официальная группа Telegram</a>, в которой публикуются основные новости
                проекта
            </li>
            <li><a href={'mailto:fndrtrn@mail.ru'}>fndrtrn@mail.ru</a> - почта для ваших предложений о помощи, идеях и
                т. д.
            </li>
        </ul>
        <hr/>
        <h2>Поддержите нас!</h2>
        <DonateBlock showInfoDevelopers/>
    </div>)
}