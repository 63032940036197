import {FC} from "react";

import './styles.css'

export const AboutProject: FC = () => {
    return <div className={'about'}>Сталкивались ли вы с тем, что, гуляя по городу, вы теряли вещь, или забывали её
        где-либо? <br/> Если да, то что делали? Писали в Подслушано своего города? А если вы гуляли не в одном
        районе? А если у города нет такой группы ВК?
        Или, может, вы нашли вещь, а куда писать, не знаете? А в вашем населенном пункте нет бюро находок?
        <br/>
        Данный сервис позволяет разместить информацию о потере или находке в одном месте и найти/вручить её владельцу с
        большой долей вероятности.
        Присоединяйтесь к нам и удачи в поиске!</div>
}