import React from 'react';
import './App.css';
import {Root} from "./components";

function App() {
  return (
   <Root/>
  );
}

export default App;
